:root {
    --hmplayer-accent: #f7b81e;
    --hmplayer-background: #000;
    --hmplayer-text-color: #fff;
    --hmplayer-border-radius: 8px;
    --hmplayer-background-hover: #f7b81e;
    --hmplayer-text-hover: #fff;
  }
  .hearme-player-wrapper {
    font-family: "Inter", Arial, Helvetica, sans-serif;
    color: var(--hmplayer-text-color);
    background-color: var(--hmplayer-background);
    border-radius: var(--hmplayer-border-radius);
    padding: 0.5rem;
    line-height: 24px;
    position: relative;
  }
  .hearme-right-controls {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  
  .hearme-secondary-controls {
      display: flex;
      justify-content: space-between;
      align-items: center;
  }
  .hearme-flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .hearme-player-floating {
    position: fixed;
    bottom: 5px;
    right: 5px;
    max-width: 350px;
    margin: 0px;
  }
  .hearme-player-floating .hearme-title {
    font-size: 13px;
  }
  .hearme-current-duration {
    align-items: center;
    font-size: 0.75rem;
    line-height: 1rem;
    padding-top: 0.25rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .hearme-playback-speed {
    background-color: var(--hmplayer-text-color);
    font-size: 11px;
    border-radius: 4px;
    padding: 1px 6px;
    color: var(--hmplayer-background);
    cursor: pointer;
    border-radius: 14px;
    margin-left: 4px;
    font-weight: bold;
  
    transition: 0.3s all;
    transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
    cursor: pointer;
  }
  .hearme-playback-speed:hover {
    color: var(--hmplayer-background) !important;
    background-color: var(--hmplayer-background-hover);
  }
  .hearme-rewind,
  .hearme-fast-forward {
    height: 18px;
    width: 18px;
  }
  .hearme-player-wrapper .hearme-rewind {
    padding-right: 0.5rem;
    height: 19px;
    width: 28px;
  }
  .hearme-title-wrapper {
    display: flex;
    min-width: 0;
    padding-bottom: 3px;
  }
  .hearme-right-controls {
    min-width: 0;
  }
  .hearme-prev-next > :not([hidden]) ~ :not([hidden]) {
    margin-right: 0.5rem;
    margin-left: 0.5rem;
  }
  .hearme-prev-next {
      display: flex;
      align-items: center;
      padding-top: 0.25rem;
  }
  .hearme-title {
    flex: 1;
    min-width: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: bold;
    font-size: 1.1rem;
    line-height: 1.75rem;
  }
  .hearme-powered-by {
    opacity: 0.6;
    color: var(--hmplayer-text-color);
    padding-top: 3px;
    margin-bottom: -3px;
  }
  .hearme-seekbar {
    position: relative;
    padding-bottom: 3px;
  }
  .hearme-seekbar-bg {
    background: var(--hmplayer-text-color);
    opacity: 0.15;
    height: 0.6rem;
    border-radius: 999px;
  }
  .hearme-seekbar .slider {
    flex-grow: 1;
    background-color: rgba(var(--hmplayer-accent-rgb), 0.2);
    cursor: pointer;
    position: relative;
    height: 0.6rem;
    border-radius: var(--hmplayer-border-radius);
  }
  .hearme-seekbar .slider .gap-progress {
    background-color: var(--hmplayer-accent);
    border-radius: inherit;
    position: absolute;
    pointer-events: none;
    border-radius: var(--hmplayer-border-radius);
  }
  .hearme-seekbar-progress {
    background: var(--hmplayer-accent);
    position: relative;
    z-index: 1;
    height: 0.6rem;
    border-radius: 999px;
    cursor: pointer;
  }
  .hearme-seekbar-progress .pin {
    height: 16px;
    width: 16px;
    border-radius: 8px;
    background-color: var(--hmplayer-accent);
    position: absolute;
    pointer-events: all;
    box-shadow: 0 1px 1px 0 var(--hmplayer-background);
    top: -3px;
    right: -8px;
  }
  .hearme-seekbar-progress .pin::after {
    content: "";
    display: block;
    background: transparent;
    width: 200%;
    height: 200%;
    margin-left: -50%;
    margin-top: -50%;
    border-radius: 50%;
}
  .hearme-play-pause svg {
    height: 66px;
  }
  .hearme-play-fill {
    fill: var(--hmplayer-background);
  }
  .hearme-play-pause {
    color: var(--hmplayer-text-color);
    position: relative;
    margin-bottom: -5px;
  }
  .hearme-pause {
    position: absolute;
    top: 0px;
    visibility: hidden;
    left: 0;
  }
  .hearme-play-pause:hover {
  }
  .hearme-volume {
      display: flex;
      align-items: center;
  }
  .hearme-volume svg {
    height: 1.25rem;
  }
  .hearme-download a {
    display: flex;
    align-items: center;
    color: var(--hmplayer-text-color);
}
  .hearme-player-button {
    transition: 0.3s all;
    transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
    cursor: pointer;
  }
  .hearme-player-button:hover {
    color: var(--hmplayer-background-hover);
  }
  .hearme-volume-button {
      display: flex;
      align-items: center;
  }
  .hearme-volume-mute {
    visibility: hidden;
    position: absolute;
  }
  .hearme-volume-button.hearme-volume-muted  .hearme-volume-full {
    visibility: hidden;
  }
  .hearme-volume-button.hearme-volume-muted  .hearme-volume-mute {
    visibility: visible;
  }

  /* volume slider */
  .hearme-volume .slider {
    flex-grow: 1;
    background-color: rgba(var(--hmplayer-accent-rgb), 0.3);
    cursor: pointer;
    position: relative;
  }
  .hearme-volume .slider .gap-progress {
    background-color: var(--hmplayer-accent);
    border-radius: inherit;
    position: absolute;
    pointer-events: none;
  }
  .hearme-volume .slider .gap-progress .pin {
    height: 8px;
    width: 8px;
    border-radius: 8px;
    position: absolute;
    pointer-events: all;
  }
  .hearme-volume .slider .gap-progress .pin::after {
    content: "";
    display: block;
    background: var(--hmplayer-accent);
    width: 200%;
    height: 200%;
    margin-left: -50%;
    margin-top: -50%;
    border-radius: 50%;
  }
  .hearme-volume .hearme-volume-controls {
    width: 30px;
    height: 135px;
    background-color: var(--hmplayer-background);
    border-radius: var(--hmplayer-border-radius);
    position: absolute;
    left: -3px;
    bottom: 52px;
    flex-direction: column;
    align-items: center;
    display: flex;
    z-index: 2;
    outline: none;
  }
  .hearme-volume .hearme-volume-controls .volume__slider {
    margin-top: 12px;
    margin-bottom: 12px;
    width: 6px;
    border-radius: var(--hmplayer-border-radius);
  }
  .hearme-volume .hearme-volume-controls .volume__slider .volume__progress {
    bottom: 0;
    height: 100%;
    width: 6px;
  }
  .hearme-volume .hearme-volume-controls .volume__slider .volume__progress .volume__pin {
    left: -1px;
    top: -8px;
  }
  .hearme-volume .hearme-volume-controls:focus {
    outline: none;
  }
  .hearme-volume .hearme-volume-controls.hidden {
    display: none;
  }
  .hearme-volume .hearme-volume-controls.top {
    bottom: 32px;
    left: -3px;
  }
  .hearme-volume .hearme-volume-controls.middle {
    bottom: -54px;
    left: 54px;
  }
  .hearme-volume .hearme-volume-controls.bottom {
    bottom: -140px;
    left: -3px;
  }
  /* end volu me slider */
  .hearme-share {
    display: flex;
    align-items: center;
  }
  .hearme-download {
    display: flex;
    align-items: center;
  }
  
  .hearme-playlist-wrapper {
    margin: 6px -8px -3px;
    max-height: 200px;
    overflow-y: auto;
    background-color: rgba(
      red(var(--hmplayer-text-color)),
      green(var(--hmplayer-text-color)),
      blue(var(--hmplayer-text-color)),
      0.2
    );
    border-top: 1px solid rgba(150, 150, 150, 0.2);
    padding-top: 6px;
  }
  .hearme-player-standard .hearme-playlist-wrapper,
  .hearme-player-minimal .hearme-playlist-wrapper  {
      display: none;
  }
  .hearme-playlist-item {
    display: flex;
    justify-content: space-between;
    padding: 3px 8px;
    margin: 0 4px;
    font-size: 13px;
    color: var(--hmplayer-text-color);
    transition: 0.3s all;
    transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
    border-radius: var(--hmplayer-border-radius);
    cursor: pointer;
    align-items: center;
    color: var(--hmplayer-text-color);
  }
  .hearme-playlist-item-playing {
    font-weight: bold;
  }
  .hearme-playing .hearme-play {
    visibility: hidden;
  }
  .hearme-playing .hearme-pause {
    visibility: visible;
  }
  .hearme-playlist-item-play-pause {
    background-color: var(--hmplayer-text-color);
    border-radius: 44px;
    display: inline-block;
    height: 18px;
    width: 18px;
    text-align: center;
    position: relative;
    top: 4px;
    margin-right: 4px;
    flex-shrink: 0;
  }
  .hearme-playlist-item-play-pause .hearme-animated-path {
    stroke-width: 0px;
    stroke: var(--hmplayer-accent) !important;
  }
  .hearme-loading .hearme-animated-path {
    stroke-dasharray: 200;
    stroke-dashoffset: 200;
    stroke: var(--hmplayer-accent);
    stroke-linecap: round;
    animation: hearme-loading-dash 1s linear infinite;
    stroke-width: 18px;
  }
  
  @keyframes hearme-loading-dash {
    from {
      stroke-dashoffset: 802;
    }
    to {
      stroke-dashoffset: 0;
    }
  }
  .hearme-playlist-item-playing .hearme-playlist-item-play-pause:before {
    border-style: double;
    border-width: 0px 0 0px 8px;
    border-radius: 0;
    left:5px;
    top: 5px;
  }
  .hearme-playlist-item-play-pause:before {
    border: 0;
    background: transparent;
    box-sizing: border-box;
    width: 0;
    height: 8px;
    border-color: transparent transparent transparent var(--hmplayer-background);
    transition: 100ms all ease;
    cursor: pointer;
    border-style: solid;
    border-width: 5px 0 5px 7px;
    content:'';
    display:block;
    position: relative;
    border-radius: 3px;;
    top: 4px;
    left: 6px;
  }
  .hearme-playlist-item-title-wrapper {
      display: flex;
  }
  .hearme-playlist-item-title {
    line-height: 1rem;
  }
  .hearme-playlist-item:hover {
    color: var(--hmplayer-text-hover);
    background: var(--hmplayer-background-hover);
  }
  .hearme-playlist-item-duration {
    font-size: 11px;
    padding-top: 1px;
  }
  .hearme-current-duration * {
    padding-left: 3px;
  }
  @media screen and (max-width: 768px) {
    .hearme-current-duration *:not(.hearme-current-progress) {
      display: none;
    }
  }
  .hearme-playlist-item-metadata {
    display: block;
    font-size: 11px;
    padding-top: 1px;
    opacity: 0.5;
  }
  .hm-heigth-4 {
    height: 1rem;
  }
  .hm-heigth-4 {
    height: 1rem;
  }
  .hm-heigth-4 {
    height: 1rem;
  }
  
  .hearme-player-wrapper [title] {
    position: relative;
  }
  
  .hearme-player-wrapper [title]:hover::after {
    content: attr(title);
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -120%);
    font-size: 12px;
    background-color: var(--hmplayer-text-color);
    color: var(--hmplayer-background);
    width:auto;
    z-index: 2;
    line-height: 12px;
    padding: 4px 8px;
    border-radius: 4px;
    text-align: center;
    box-shadow: 0px 0px 0px 2px var(--hmplayer-background);
    white-space: nowrap;
    font-weight: normal;
  }

  .hearme-hidden {
      display: none !important;
  }

  

  /* twcss */

.absolute {
    position: absolute;
  }
  
  .relative {
    position: relative;
  }
  
  .inset-0 {
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
  }
  
  .top-1\/2 {
    top: 50%;
  }
  
  .left-1\/2 {
    left: 50%;
  }
  
  .top-0 {
    top: 0px;
  }
  
  .left-0 {
    left: 0px;
  }
  
  .-top-px {
    top: -1px;
  }
  
  .-left-1 {
    left: -0.25rem;
  }
  
  .left-3 {
    left: 0.75rem;
  }
  
  .left-1 {
    left: 0.25rem;
  }
  
  .top-1 {
    top: 0.25rem;
  }
  
  .m-4 {
    margin: 1rem;
  }
  
  .mt-2 {
    margin-top: 0.5rem;
  }
  
  .mb-3 {
    margin-bottom: 0.75rem;
  }
  
  .-mt-2 {
    margin-top: -0.5rem;
  }
  
  .mt-1 {
    margin-top: 0.25rem;
  }
  
  .mb-2 {
    margin-bottom: 0.5rem;
  }
  
  .mb-1 {
    margin-bottom: 0.25rem;
  }
  
  .-mt-1 {
    margin-top: -0.25rem;
  }
  
  .-mt-px {
    margin-top: -1px;
  }
  
  .ml-1 {
    margin-left: 0.25rem;
  }
  
  .ml-3 {
    margin-left: 0.75rem;
  }
  
  .inline-block {
    display: inline-block;
  }
  
  .flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  
  .flex-1 {
    -webkit-box-flex: 1;
        -ms-flex: 1 1 0%;
            flex: 1 1 0%;
  }
  
  .flex-shrink-0 {
    -ms-flex-negative: 0;
        flex-shrink: 0;
  }
  
  .flex-grow-0 {
    -webkit-box-flex: 0;
        -ms-flex-positive: 0;
            flex-grow: 0;
  }
  
  .flex-grow {
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
  }
  
  .p-4 {
    padding: 1rem;
  }
  
  .py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  
  .px-3 {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
  
  .px-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  
  .px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  
  .py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  
  .py-12 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  
  .py-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }
  
  .pt-1 {
    padding-top: 0.25rem;
  }
  
  .pt-3 {
    padding-top: 0.75rem;
  }
  
  .align-middle {
    vertical-align: middle;
  }
  
  .text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
  
  .text-xs {
    font-size: 0.75rem;
    line-height: 1rem;
  }
  
  .font-bold {
    font-weight: 700;
  }
  
  .opacity-50 {
    opacity: 0.5;
  }
  
  .\[mask-image\:linear-gradient\(180deg\2c white\2c rgba\(255\2c 255\2c 255\2c 0\)\)\] {
    -webkit-mask-image: -webkit-gradient(linear,left top, left bottom,from(white),to(rgba(255,255,255,0)));
    -webkit-mask-image: linear-gradient(180deg,white,rgba(255,255,255,0));
            mask-image: -webkit-gradient(linear,left top, left bottom,from(white),to(rgba(255,255,255,0)));
            mask-image: linear-gradient(180deg,white,rgba(255,255,255,0));
  }
  
  @media (min-width: 640px) {
    .sm\:py-12 {
      padding-top: 3rem;
      padding-bottom: 3rem;
    }
  }