.ce-blockquote {
  line-height: 1.6em;
  outline: none;
  border-left: 4px solid #000;
  padding-left: 16px;
  padding-top: 0;
  padding-bottom: 0;
  margin-top: 4px;
  margin-bottom: 4px;
}

.ce-blockquote[data-placeholder]:empty::before{
  content: attr(data-placeholder);
  color: #707684;
  font-weight: normal;
  opacity: 0;
}

/** Show placeholder at the first paragraph if Editor is empty */
.codex-editor--empty .ce-block:first-child .ce-blockquote[data-placeholder]:empty::before {
opacity: 1;
}

.codex-editor--toolbox-opened .ce-block:first-child .ce-blockquote[data-placeholder]:empty::before,
.codex-editor--empty .ce-block:first-child .ce-blockquote[data-placeholder]:empty:focus::before {
opacity: 0;
}

.ce-blockquote p:first-of-type{
  margin-top: 0;
}

.ce-blockquote p:last-of-type{
  margin-bottom: 0;
}