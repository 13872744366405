.ce-inline-tool--alias .icon {
  height: 7px;
  top: 0px;
  left: 1px;
  position: relative;
  display: none;
}
.ce-inline-tool--alias.ce-inline-tool--active .icon {
  display: inline-block;
}
.ce-inline-tool--alias.ce-inline-tool--active:hover {
  @apply text-red-300
}
.ce-inline-tool--alias {
  border-right: 1px solid #374151;
  padding-right: 10px !important;
  padding-left: 10px !important;
  margin-left: -6px !important;
  white-space: nowrap;
}
.ce-block [data-title] {
  position: relative;
}
.ce-block a[href] {
  @apply bg-blue-600 text-white;
  border-radius: 4px;
  padding: 2px 4px;
  text-decoration: none;
}
