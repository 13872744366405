@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;400;600&display=swap');
@tailwind base;
@tailwind components;
@tailwind forms;

@tailwind utilities;

/* TODO: FIX RING */
.ring-green-500 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(34 197 94 / var(--tw-ring-opacity)) !important;
}

body {
  font-family: "Inter", sans-serif;
}
.vh {
  height: calc(100vh - 64px);
}
a:hover {
  text-decoration: none;
}
.loading {
  position: relative;
  border-width: 0px !important;
}
.loading:before {
  position: absolute;
  top:0;
  left:0;
  bottom:0;
  right:0;
  background: #fff;
  opacity: 0.7;
  content: "";
  box-sizing: border-box;
}
.loading:after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid #ccc;
  border-top-color: #000;
  animation: spinner .6s linear infinite;
}

/* input.tag-left {
  @apply rounded-none rounded-r-md !important;
}
input.tag-right {
  @apply rounded-none rounded-l-md !important;
} */
input.error {
  /* @apply focus:ring-current focus:border-blue-500 border-red-500 !important; */
  border-color: rgb(239, 68, 68);
}
.custom-select-item {
  @apply text-gray-900 cursor-pointer select-none relative py-2 pl-3 pr-9;
}
.custom-select-item.selected {
  @apply text-white bg-blue-600;
}
/* .sidebar-item {
  @apply text-hm-gray hover:bg-gray-700 hover:text-white flex items-center px-2 py-2 text-sm font-medium rounded-md;
} */
.sidebar-item.router-link-active {
  @apply bg-hm-dark text-white flex items-center px-2 py-2 text-sm font-medium rounded-md;
}
.sidebar-item.router-link-active svg {
  @apply text-hm-brand;
}
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-property: height, opacity;
  transition-timing-function: ease;
  overflow: hidden;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}

.fade-fast-enter-active,
.fade-fast-leave-active {
  transition-duration: 0.2s;
  transition-property: opacity;
  transition-timing-function: ease;
  overflow: hidden;
}
.fade-fast-leave-active {
  /* position: absolute; */
}

.fade-fast-enter,
.fade-fast-leave-active {
  opacity: 0
}


.gradient-white-b-t {
  background: rgb(255,255,255);
  background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 35%);
}
.PUBLISHED, .published {
  @apply bg-green-200 text-green-800;
}
.publishing {
  @apply bg-yellow-200 text-yellow-800;
}
.PENDING {
  @apply bg-yellow-200 text-yellow-800;
}
.FAILED, .failed {
  @apply bg-red-200 text-red-800;
}
.NEW, .UNPUBLISHED, .unpublished {
  @apply bg-blue-200 text-blue-800;
}
.FETCHED {
  @apply bg-blue-200 text-blue-800;
}



/* PAGiNATION */
.VuePagination__count {
  @apply text-gray-500 text-sm mt-2;
}
.page-item.active {
  @apply border-blue-500 text-blue-600 !important;
}
.page-item.disabled {
  @apply opacity-40 pointer-events-none select-none !important;
}
@keyframes spinner {
  to {transform: rotate(360deg);}
}
.loading {
  position: relative;
  border-width: 0px !important;
}
.loading:before {
  position: absolute;
  top:0;
  left:0;
  bottom:0;
  right:0;
  background: #fff;
  opacity: 0.7;
  content: "";
  box-sizing: border-box;
  @apply rounded-md;
}
.loading:after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid #ccc;
  border-top-color: #000;
  animation: spinner .6s linear infinite;
}

:root {
  --plyr-color-main: #008AFF !important;
}

.color-input {
  display:none;
}
.popper {
  @apply shadow-md rounded-md p-0 border-none;
}

.bg-hm-brand .text-gray-500, .bg-red-500 .text-gray-500 {
  @apply text-white !important;
}
.bg-hm-brand .text-yellow-400 {
  @apply text-white !important;
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}
.min-h-half {
  min-height: 50vh;
}


/* SPINNER */
.spinner {
  -webkit-animation: rotator 1.4s linear infinite;
          animation: rotator 1.4s linear infinite;
}

@-webkit-keyframes rotator {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(270deg);
  }
}

@keyframes rotator {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(270deg);
  }
}
.path {
  stroke-dasharray: 187;
  stroke-dashoffset: 0;
  transform-origin: center;
  -webkit-animation: dash 1.4s ease-in-out infinite, colors 5.6s ease-in-out infinite;
          animation: dash 1.4s ease-in-out infinite, colors 5.6s ease-in-out infinite;
}

@-webkit-keyframes colors {
  0% {
    stroke: #FEA900;
  }
  25% {
    stroke: #FEA900;
  }
  50% {
    stroke: #FEA900;
  }
  75% {
    stroke: #FEA900;
  }
  100% {
    stroke: #FEA900;
  }
}

@keyframes colors {
  0% {
    stroke: #FEA900;
  }
  25% {
    stroke: #FEA900;
  }
  50% {
    stroke: #FEA900;
  }
  75% {
    stroke: #FEA900;
  }
  100% {
    stroke: #FEA900;
  }
}
@-webkit-keyframes dash {
  0% {
    stroke-dashoffset: 187;
  }
  50% {
    stroke-dashoffset: 46.75;
    transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: 187;
    transform: rotate(450deg);
  }
}
@keyframes dash {
  0% {
    stroke-dashoffset: 187;
  }
  50% {
    stroke-dashoffset: 46.75;
    transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: 187;
    transform: rotate(450deg);
  }
}

/* // EDITOR JS */
#editorjs h1 {
  @apply text-2xl font-bold;
}
#editorjs h2 {
  @apply text-xl font-bold;
}
#editorjs h3 {
  @apply text-lg font-bold;
}
#editorjs h4 {
  @apply text-base font-bold;
}
#editorjs h5 {
  @apply text-sm font-bold;
}
#editorjs h5 {
  @apply text-xs font-bold;
}
.ce-inline-toolbar {
  @apply bg-black rounded-md;
}
.ce-inline-tool-input {
  @apply bg-black text-white
}
.ce-inline-toolbar__dropdown {
  @apply rounded-r-none rounded-l-md
}
.ce-inline-toolbar .ce-inline-tool, .ce-inline-toolbar .ce-inline-toolbar__dropdown {
  @apply text-white bg-black;
}
.ce-inline-toolbar .ce-inline-tool svg,
.ce-inline-toolbar .ce-inline-tool svg path {
  fill: #fff;
}
.ce-inline-toolbar .ce-inline-tool:hover {
  @apply bg-gray-600;
}

.ce-inline-toolbar


[data-title]:hover:before {
  opacity: 1;
  transition: all 0.1s ease 0.3s;
  visibility: visible;
}
[data-title]:before {
  content: attr(data-title);
  position: absolute;
  bottom: -1.6em;
  left: 100%;
  padding: 4px 4px 4px 8px;
  color: #222;
  white-space: nowrap; 
  -moz-border-radius: 5px; 
  -webkit-border-radius: 5px;  
  border-radius: 5px;  
  -moz-box-shadow: 0px 0px 4px #222;  
  -webkit-box-shadow: 0px 0px 4px #222;  
  box-shadow: 0px 0px 4px #222;  
  background-image: -moz-linear-gradient(top, #f8f8f8, #cccccc);  
  background-image: -webkit-gradient(linear,left top,left bottom,color-stop(0, #f8f8f8),color-stop(1, #cccccc));
  background-image: -webkit-linear-gradient(top, #f8f8f8, #cccccc);  
  background-image: -moz-linear-gradient(top, #f8f8f8, #cccccc);  
  background-image: -ms-linear-gradient(top, #f8f8f8, #cccccc);  
  background-image: -o-linear-gradient(top, #f8f8f8, #cccccc);
  opacity: 0;
  z-index: 99999;
  visibility: hidden;
}




/* Popover */
/*! CSS Used from: Embedded */
::-moz-selection{background-color:#d4ecff;}
::selection{background-color:#d4ecff;}
/*! CSS Used from: Embedded */
*,*::before,*::after{box-sizing:border-box;}
button{touch-action:manipulation;}
button{margin:0;font-family:inherit;font-size:inherit;line-height:inherit;color:inherit;}
button{overflow:visible;}
button{text-transform:none;}
button,html [type="button"]{-webkit-appearance:button;}
button::-moz-focus-inner,[type="button"]::-moz-focus-inner{padding:0;border-style:none;}
::-moz-selection{background:#000;color:#fff;}
::selection{background:#000;color:#fff;}
.anticon{display:inline-block;font-style:normal;vertical-align:baseline;text-align:center;text-transform:none;line-height:1;text-rendering:optimizeLegibility;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;}
.anticon:before{display:block;font-family:"anticon"!important;}
.anticon-exclamation-circle:before{content:"\e62c";}
/*! CSS Used from: Embedded */
.ant-popover{font-size:14px;line-height:1.5;color:rgba(0, 0, 0, 0.65);box-sizing:border-box;margin:0;padding:0;list-style:none;position:absolute;top:0;left:0;z-index:1030;cursor:auto;-webkit-user-select:text;-moz-user-select:text;-ms-user-select:text;user-select:text;white-space:normal;font-weight:normal;text-align:left;}
.ant-popover:after{content:"";position:absolute;background:rgba(255, 255, 255, 0.01);}
.ant-popover-placement-top{padding-bottom:9px;}
.ant-popover-inner{background-color:#fff;background-clip:padding-box;border-radius:4px;box-shadow:0 2px 8px rgba(0, 0, 0, 0.15);}
.ant-popover-inner-content{padding:12px 16px;color:rgba(0, 0, 0, 0.65);}
.ant-popover-message{padding:4px 0 12px;font-size:14px;color:rgba(0, 0, 0, 0.65);}
.ant-popover-message > .anticon{color:#FFB300;line-height:1.6;position:absolute;}
.ant-popover-message-title{padding-left:22px;}
.ant-popover-buttons{text-align:right;margin-bottom:4px;}
.ant-popover-buttons button{margin-left:8px;}
.ant-popover-arrow{background:#fff;width:7.07106781px;height:7.07106781px;transform:rotate(45deg);position:absolute;display:block;border-color:transparent;border-style:solid;}
.ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow{bottom:6px;box-shadow:3px 3px 7px rgba(0, 0, 0, 0.07);}
.ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow{left:50%;transform:translateX(-50%) rotate(45deg);}
/*! CSS Used from: Embedded */
.ant-btn{line-height:1.5;display:inline-block;font-weight:400;text-align:center;touch-action:manipulation;cursor:pointer;background-image:none;border:1px solid transparent;white-space:nowrap;padding:0 24px;font-size:14px;border-radius:4px;height:32px;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;transition:all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);position:relative;color:#000;background-color:#fff;border-color:#000;}
.ant-btn,.ant-btn:active,.ant-btn:focus{outline:0;}
.ant-btn:not([disabled]):hover{text-decoration:none;}
.ant-btn:not([disabled]):active{outline:0;transition:none;}
.ant-btn-sm{padding:0 7px;font-size:14px;border-radius:4px;height:24px;}
.ant-btn:hover,.ant-btn:focus{color:#1d4ed8;background-color:#fff;border-color:#1d4ed8;}
.ant-btn:active{color:#009969;background-color:#fff;border-color:#009969;}
.ant-btn:hover,.ant-btn:focus,.ant-btn:active{background:#fff;text-decoration:none;}
.ant-btn > span{pointer-events:none;}
.ant-btn-default{color:#fff;background-color:#000;border-color:#000; line-height: 12px;}
.ant-btn-primary:hover,.ant-btn-primary:focus{color:#fff;background-color:#1d4ed8;border-color:#1d4ed8;}
.ant-btn-primary:active{color:#fff;background-color:#009969;border-color:#009969;}
.ant-btn:before{position:absolute;top:-1px;left:-1px;bottom:-1px;right:-1px;background:#fff;opacity:0.35;content:'';border-radius:inherit;z-index:1;transition:opacity .2s;pointer-events:none;display:none;}
.ant-btn:focus > span,.ant-btn:active > span{position:relative;}
/*! CSS Used from: Embedded */
*,::before,::after{box-sizing:border-box;}
button{font-family:inherit;font-size:100%;line-height:1.15;margin:0;}
button{text-transform:none;}
button,[type='button']{-webkit-appearance:button;}
::-moz-focus-inner{border-style:none;padding:0;}
:-moz-focusring{outline:1px dotted ButtonText;}
button{background-color:transparent;background-image:none;}
*,::before,::after{box-sizing:border-box;border-width:0;border-style:solid;border-color:currentColor;}
button{cursor:pointer;}
:-moz-focusring{outline:auto;}
button{padding:0;line-height:inherit;color:inherit;}
*,::before,::after{--tw-border-opacity:1;border-color:rgba(229, 231, 235, var(--tw-border-opacity));}
*,::before,::after{--tw-shadow:0 0 #0000;}
*,::before,::after{--tw-ring-inset:var(--tw-empty, );--tw-ring-offset-width:0px;--tw-ring-offset-color:#fff;--tw-ring-color:rgba(59, 130, 246, 0.5);--tw-ring-offset-shadow:0 0 #0000;--tw-ring-shadow:0 0 #0000;}
.ant-popover{font-size:14px;line-height:1.5;color:rgba(0, 0, 0, 0.65);box-sizing:border-box;margin:0;padding:0;list-style:none;position:absolute;top:0;left:0;z-index:1030;cursor:auto;-webkit-user-select:text;-moz-user-select:text;-ms-user-select:text;user-select:text;white-space:normal;font-weight:normal;text-align:left;}
.ant-popover:after{content:"";position:absolute;background:rgba(255, 255, 255, 0.01);}
.ant-popover-placement-top{padding-bottom:9px;}
.ant-popover-inner{background-color:#fff;background-clip:padding-box;border-radius:4px;box-shadow:0 2px 8px rgba(0, 0, 0, 0.15);}
.ant-popover-inner-content{padding:12px 16px;color:rgba(0, 0, 0, 0.65);}
.ant-popover-message{padding:4px 0 12px;font-size:14px;color:rgba(0, 0, 0, 0.65);}
.ant-popover-message > .anticon{color:#FFB300;line-height:1.6;position:absolute;}
.ant-popover-message-title{padding-left:22px;}
.ant-popover-buttons{text-align:right;margin-bottom:4px;}
.ant-popover-buttons button{margin-left:8px;}
.ant-popover-arrow{background:#fff;width:7.07106781px;height:7.07106781px;transform:rotate(45deg);position:absolute;display:block;border-color:transparent;border-style:solid;}
.ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow{bottom:6px;box-shadow:3px 3px 7px rgba(0, 0, 0, 0.07);}
.ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow{left:50%;transform:translateX(-50%) rotate(45deg);}
/*! CSS Used fontfaces */
@font-face{font-family:'anticon';src:url(https://at.alicdn.com/t/font_148784_v4ggb6wrjmkotj4i.eot);src:url(https://at.alicdn.com/t/font_148784_v4ggb6wrjmkotj4i.woff) format('woff'),  url(https://at.alicdn.com/t/font_148784_v4ggb6wrjmkotj4i.ttf) format('truetype'),  url(https://at.alicdn.com/t/font_148784_v4ggb6wrjmkotj4i.svg#iconfont) format('svg');}
