






















































































































































































































































































































































































































































.title-editor .ce-inline-toolbar__toggler-and-button-wrapper > :first-child {
  display: none;
}
.title-editor .ce-paragraph {
  height: 0;
}
.title-editor .ce-toolbar {
  display: none !important;
}
.title-editor.default h1 {
  margin: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  font-size: 1.875rem;
  line-height: 2.25rem;
  font-weight: 700;
  margin-top: 0px !important;
}
.title-editor.input {
  margin-top: -13px;
}
.title-editor.input .ce-block .play-btn {
  top: -5px;
  left: 2px;
  border-radius: 14px;
  position: absolute !important;
}
.title-editor.input .ce-block__content {
  position: relative;
  max-width: 100%;
  margin: 0 auto;
  padding-left: 39px;
}
.title-editor .codex-editor__redactor {
  padding-bottom: 0px !important;
}
.title-editor .ce-block {
  position: relative;
}
.title-editor .ce-block .ce-header {
  padding-top: 0px;
  margin-top: 1em;
}
.title-editor .ce-block .play-btn {
  top: 2px;
  left: -12px;
  border-radius: 14px;
  position: absolute !important;
}
.title-editor .ce-block .play-btn .editor-play {
  display: block;
}
.title-editor .ce-block .play-btn .editor-stop {
  display: none;
  left: 3px;
  top: 2px;
  position: relative;
}
.title-editor .ce-block .play-btn.playing .editor-play {
  display: none;
}
.title-editor .ce-block .play-btn.playing .editor-stop {
  display: block;
}

@media screen and (max-width: 768px) {
  .title-editor .ce-block__content {
    padding-left: 22px;
  }
}
