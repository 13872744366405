break[time] {
  content: attr(time);
}

break[time] {
  @apply bg-red-600 text-white inline-block;
  border-radius: 4px;
  padding: 2px 4px;
  margin-right: 2px;
  margin-left: 2px;
  text-decoration: none;
  height: 100%;
  bottom: -9px;
  position: relative;
  border: 0px;
  margin-top: -16px;
  height: 24px;
  line-height: 21px;
  position: relative;
  top: 6px;
  font-size: 15px;
  font-weight: normal;
  vertical-align: middle;
  pointer-events: none;
}
h1 break[time] {
  top: 5px;
}
@-moz-document url-prefix() {
  break[time]  {
    bottom: 0;
  }
}
break[time]:after {
  display: inline-block;;
  content: attr(time);
  pointer-events: none;
}
break:before {
  font-family: 'Font Awesome 5 Pro';
  content: "\f017 \00a0";
  pointer-events: none;
}

.ce-inline-break {
  @apply text-sm;
}
.ce-inline-break svg {
  @apply mr-1;
}
@media screen and (min-color-index:0) and(-webkit-min-device-pixel-ratio:0)
{ @media {
    break[time] {
      vertical-align: middle;
    }
}}